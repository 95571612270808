<template>
  <div class="notice">
    <div class="cont">
      <van-cell>
        <van-row>
          <van-col :span="6" class="file-icon"><img src="@/assets/img/file_icon.png"/></van-col>
          <van-col :span="10" class="txt">非常规内容</van-col>
          <van-col :span="6" class="download">
            <van-button type="info" size="small" round @click="copyUrl()"><img src="@/assets/img/download.png"/>下载文件</van-button>
          </van-col>
          <van-col :span="10" class="text">除日常任务外其他任务</van-col>
        </van-row>
      </van-cell>
    </div>
    <div v-if="dataForm.handleId !== null" class="visitInfo">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">基础信息</span>
          </template>
        </van-cell>
        <van-cell title="任务归属" :value="dataList.scopeType == 1?'社区':'网格'" title-style="font-weight:600"/>
        <van-cell title="截至时间" :value="dataList.endTime" title-style="font-weight:600"/>
        <van-cell title="任务备注" :value="dataList.remark" title-style="font-weight:600"/>
        <van-cell title="任务状态" :value="dataList.taskStatusStr" title-style="font-weight:600"/>
        <van-cell title="执行人" :value="dataList.name" title-style="font-weight:600"/>
        <van-cell title="申领时间" :value="dataList.claimTime" title-style="font-weight:600"/>
<!--        <van-cell title="文件名称" :value="dataForm.targetStr" title-style="font-weight:600"/>-->
<!--        <van-cell title="文件类型" :value="dataForm.object" title-style="font-weight:600"/>-->
        <van-cell title="任务文件" :value="resultList" title-style="font-weight:600"/>
        <van-cell title="完成时间" :value="dataList.handleTime" title-style="font-weight:600"/>
      </van-cell-group>
      <van-button type="info" size="large" :loading="loading" round @click="reUpload" class="btn" v-show="dataList.checkStatus == 3">重新上传</van-button>
    </div>
    <div class="cont" v-if="dataForm.handleId == null">
<!--      <van-popup v-model="fileTypeShow" position="bottom">-->
<!--        <van-picker title="请选择" show-toolbar :columns="fileTypeList" value-key="label"-->
<!--                    @confirm="fileTypeConfirm" @cancel="fileTypeShow = false"/>-->
<!--      </van-popup>-->
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span class="custom-title">文件上传</span>
          </template>
        </van-cell>
<!--        <van-cell title="文件名称">-->
<!--          <van-field v-model="dataForm.fileName" placeholder="请输入" :readonly="!edit"/>-->
<!--        </van-cell>-->
<!--        <van-cell title="文件类型" :value="dataForm.fileTypeStr" @click="fileTypeShow = edit" :clickable="edit"/>-->
          <van-row class="file">
            <van-col :span="4">上传文件</van-col>
            <van-col :span="6">
              <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead" :before-read="beforeUpload" :accept="'.*'">
<!--                <van-button icon="plus" type="default">点击上传</van-button>-->
                <img src="@/assets/img/file_upload.png"/>
              </van-uploader>
            </van-col>
            <van-col :span="12">
              <span>上传文件支持表格、文档等任务文件，文件大小不超100M。</span>
            </van-col>
          </van-row>
        <van-cell title="任务备注:">
          <van-field v-model="fileData.remark" rows="4" autosize type="textarea" placeholder="请输入任务要求或目标" style="padding-top: 0;"/>
        </van-cell>
      </van-cell-group>
      <van-row class="btn">
        <van-button type="info" size="large" round @click="goSubmit()">上传结果</van-button>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, dowloadByUrl} from "@/utils/utils"
export default {
  components :{
    topBar
  },
  data() {
    return {
      loading: false,
      helpShow: false,
      activeName: '1',
      dataList: [],
      resultList: '',
      dataForm: {
        // id: 0,
        // fileTypeStr: '请选择',
        // fileType: '',
        // fileName: '',
        // des: '',
        // newFileMessages: [],
        // deleteFiles: []
      },
      fileData:{
        newFileMessages: [],
        remark: ''
      },
      result: {
        loading: false,
        finished: false,
      },
      edit: false,
      fileTypeShow: false,
      fileTypeList: [],
      fileList: []
    };
  },
  created() {
    debugger
    this.dataForm = this.$store.state.otherData
    this.edit = false
    if (this.$route.query.handleId) {
      this.dataForm.handleId = this.$route.query.handleId
    }
    if (this.dataForm.handleId !== null) {
      this.getInfo()
    }
  },
  methods: {
    // // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.dataForm.newFileMessages.splice(i, 1)
      // if (file.id !== 0) {
      //   this.dataForm.deleteFiles.push(file.id)
      // }
    },
    // 重新上传
    reUpload () {
      this.dataForm.handleId = null
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/inspectionPicture/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.fileData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // // 文件类型选择
    // fileTypeConfirm(value) {
    //   if (value) {
    //     this.dataForm.fileTypeStr = value.label
    //     this.dataForm.fileType = value.value
    //   }
    //   this.fileTypeShow = false
    // },
    copyUrl () {
      if (this.dataList.filesPath) {
        let division = ''
        let path = ''
        this.dataList.filesPath.map(item => {
          path = item.filePath
          division = item.filePath.split('?')[0].split('.')[4]
          dowloadByUrl(path, item.fileName + '.' + division)
        })
      }
      // let cInput = document.createElement('input')
      // cInput.value = fileUrl
      // document.body.appendChild(cInput)
      // cInput.select()
      // document.execCommand('Copy')
      // this.$toast.success('复制成功')
      // cInput.remove()
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/assignTasks/otherTaskInfo`),
        method: 'post',
        params: this.$http.adornParams({
          id: this.dataForm.id
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          debugger
          this.dataList = data.data
          let resultFile = []
          this.dataList.resultFilesPath.map(item => {
            resultFile.push(item.oldFileName)
          })
          this.resultList = resultFile.join(' | ')
          // 加载状态结束
          this.result.finished = true
          this.result.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    goSubmit () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/assignTasks/otherTaskSaveResult`),
        method: 'post',
        data: this.$http.adornData({
          id: this.dataForm.id,
          newFileMessages: this.fileData.newFileMessages,
          remark: this.fileData.remark
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: '上传成功',
            duration: 1500,
            onClose: () => {
              this.$router.go(-1)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-field__control {
  text-align: right !important;
}
.visitInfo {
  margin-top: 20px;
  background-color: #ffffff !important;
  ::v-deep .van-cell {
    line-height: 56px !important;
  }
  .btn {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }
}
.custom-title {
  font-weight: 600;
}
.check {
  color: #3F8BFC;
}
.cont {
  ::v-deep .van-field {
    margin-left: 30px;
  }
  .file-icon {
    img {
      width: 50%;
      margin-left: 30px;
      margin-top: 25px;
    }
  }
  .text {
    font-size: 24px;
  }
  .txt {
    margin-top: 28px;
  }
  .download {
    font-size: 36px;
    color: #02A7F0;
    margin-top: 20px;
    img {
      width: 20px;
      height: 100%;
      margin-right: 8px;
    }
    ::v-deep .van-button--small {
      height: 57px;
      width: 195px;
      border-radius: 10px;
    }
    ::v-deep .van-button--small {
      padding-bottom: 5px;
    }
  }
  .file {
    margin-left: 20px;
    margin-top: 20px;
    .van-col:nth-child(1) {
      font-size: 28px;
      margin: 20px 10px;
    }
    .van-col:nth-child(2) {
      margin-right: 10px;
      margin-top: 15px;
      img {
        width: 170px;
        height: 170px;
      }
    }
    .van-col:nth-child(3) {
      color: #999999;
      margin-top: 20px;
    }
  }
  .btn {
    position: fixed;
    bottom: 30px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    .van-col:nth-child(1) {
      margin-right: 55px;
    }
  }
  .van-list {
    .van-row:nth-child(2) .van-col:nth-child(2) {
      .van-button--small {
        margin-left: 25px;
      }
    }
  }
}
</style>
